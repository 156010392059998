import { GET_REQUEST, POST_REQUEST, PUT_REQUEST } from '@/services/http';
import QuizBannerModel from '@/views/app/quizBanner/model/QuizBannerModel'
import apiPath from '@/services/apiPath';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';

export default class QuizBannerViewModel {
  constructor() {
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.model = new QuizBannerModel()
    this.valid = {
      code:{
        value: 'code',
        isValid:false,
        validAlertDesc: 'code를 입력해주세요',
      },
      priority:{
        value: 'priority',
        valueType:'number',
        isValid:false,
        validAlertDesc: '우선순위를 입력해주세요',
      },
      link_url:{
        value: 'link_url',
        isValid:false,
        validAlertDesc: '타겟구분을 입력해주세요',
      },
      image_url_list:{
        value: 'image_url_list',
        valueType:'array',
        isValid:false,
        validAlertDesc: '베너이미지를 입력해주세요',
      },
      start_at: {
        value: 'start_at',
        isValid: false,
        validAlertDesc: '게시기간을 입력해주세요',
      },
      end_at: {
        value: 'end_at',
        isValid: false,
        validAlertDesc: '게시기간을 입력해주세요',
      },
    }
    this.boardData = {
      title:'베너 목록',
      drawDataList: [
        {
          title:'ID',
          width:'60px',
          value:'id',
        },
        {
          title:'CODE',
          width:'120px',
          value:'code',
          isAlignLeft: true,
        },
        {
          title: '베너 이미지',
          width: '120px',
          value: 'image_url',
          img: {
            size: 'contain',
            width: '105px',
            height: '55px',
            position: {
              x: 'center',
              y: 'center',
            },
          },
        },
        {
          title:'베너명/설명',
          width:'',
          value:'title',
          valueCustom: [
            {
              type: 'text',
              value: '<strong>',
            },
            {
              type: 'value',
              value: 'title',
            },
            {
              type: 'text',
              value: '</strong><br>',
            },
            {
              type: 'value',
              value: 'description',
            },
          ],
          isAlignLeft: true,
          isEllip: true,
        },
        {
          title:'링크',
          width:'112px',
          value:'link_url',
          isAlignLeft: true,
          isEllip: true
        },
        {
          title:'우선순위',
          width:'64px',
          value:'priority'
        },
        {
          title: '게시기간',
          width: '110px',
          value: '',
          dateRangeValue:{
            startValue: 'start_at',
            endValue: 'end_at',
            betweenText: ' ~ ',
            isEndPastDateToRed: true
          },
          isAlignLeft: true,
        },
        {
          title:'상태',
          width:'74px',
          value:'status',
          filter: {
            name: 'convertIdToText',
            value: 'common_status',
          },
          badge: {
            badgeColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'common_status',
            },
            badgeFill: false,
          },
        },
      ],
      option:{
        isTotal: true,
        isSize: true,
      },
    }
    this.isRegisterPopup = false;
  }
  init(){
    this.getQuizBannerList();
  }

  validatedResult(){
    return pageValidated(this.valid, this.model)
  }
  onSearch(){
    this.getQuizBannerList();
  }
  onClickBannerRegister(){
    this.isRegisterPopup = true;
  }
  onClickRow(rowId,data){
    this.model.setData(data);
    this.isRegisterPopup = true;
  }
  onClickCloseQuizBannerRegisterPopup(){
    this.isRegisterPopup = false;
    this.resetData();
  }
  onClickCompleteQuizBannerRegisterPopup(){
    console.log(this.model)
    if(this.model.id){
      this.putQuizBannerModify(this.model.id)
    }else{
      this.postQuizBannerRegister()
    }
  }
  resetData(){
    this.model = new QuizBannerModel();
  }

  // [API] 목록
  getQuizBannerList(){
    const searchParams = {
      page: this.searchParams.page,
      size: this.searchParams.pageSize
    }
    const query = makeQueryStringByObject(searchParams);
    const path = `${apiPath.QUIZ_BANNER_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.dataList = resultData.advertisements;
      this.paginationData.totalCount = resultData.total_elements;
      this.paginationData.totalPage = resultData.total_pages;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 등록
  postQuizBannerRegister(){
    const path = `${apiPath.QUIZ_BANNER_REGISTER}`;
    const body = this.model.getData();
    POST_REQUEST(path,body).then(
    (success) => {
      const resultData = success.data;
      this.getQuizBannerList();
      this.isRegisterPopup = false;
      this.resetData();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 수정
  putQuizBannerModify(id){
    const path = `${apiPath.QUIZ_BANNER_MODIFY.format(id)}`;
    const body = this.model.getData();
    PUT_REQUEST(path, body).then(
    (success) => {
      const resultData = success.data;
      this.getQuizBannerList();
      this.isRegisterPopup = false;
      this.resetData();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

}