<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="(id,data) => viewModel.onClickRow(id,data)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="베너 등록"
          @onClickBtn="viewModel.onClickBannerRegister()">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
    <template v-slot:popup>
      <QuizBannerRegisterPopup
        v-if="viewModel.isRegisterPopup"
        :isVaild="viewModel.validatedResult()"
        :model.sync="viewModel.model"
        @onClickClose="viewModel.onClickCloseQuizBannerRegisterPopup()"
        @onClickComplete="viewModel.onClickCompleteQuizBannerRegisterPopup()"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
//popup
import QuizBannerRegisterPopup from '@/views/app/quizBanner/view/popup/QuizBannerRegisterPopup'
// viewModel
import QuizBannerViewModel from '@/views/app/quizBanner/viewModel/QuizBannerViewModel';

export default {
  name: 'QuizBanner',
  components: {
    PageWithLayout,
    Board,
    Button,
    IconSvg,
    QuizBannerRegisterPopup
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new QuizBannerViewModel(),
    }
  }
}
</script>

<style scoped>
</style>
