import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

export default class BannerModel {
  constructor() {
    this.objRaw = {};

    this.id = '';
    this.code = ''; // 코드 (기록용)
    this.priority = null; // 우선순위
    this.title = '';
    this.description = '';
    this.image_url = ''; // 이미지
    this.image_url_list = [];
    this.link_url = ''; // 링크
    this.start_at = convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss');
    this.end_at = convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss');
    this.status = 'REGISTERED';
    this.inventory = 'LEARNING'; // 광고위치 고정
    this.is_advertisement = true; // 광고마크
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const {
      id,
      code,
      is_advertisement,
      title,
      description,
      link_url,
      image_url,
      priority,
      inventory,
      start_at,
      end_at,
      status,
    } = obj;
    this.id = id;
    this.image_url = image_url;
    if (image_url) {
      this.image_url_list = [
        {
          src: image_url,
        },
      ];
    }
    this.code = code;
    this.is_advertisement = is_advertisement;
    this.title = title;
    this.description = description;
    this.link_url = link_url;
    this.priority = priority;
    this.inventory = inventory;
    this.start_at = start_at;
    this.end_at = end_at;
    this.status = status;
  }
  getData() {
    let obj = {
      code: this.code,
      title: this.title,
      description: this.description,
      image_url:
        this.image_url_list.length > 0 ? this.image_url_list[0].src : '',
      link_url: this.link_url,
      status: this.status,
      inventory: this.inventory,
      is_advertisement: this.is_advertisement,
      priority: Number(this.priority),
      start_at: convertDateFormat(this.start_at,'YYYY-MM-DDTHH:mm:ss'),
      end_at: convertDateFormat(this.end_at,'YYYY-MM-DDTHH:mm:ss'),
    };
    return obj;
  }
}
